import apiCall from 'app/libs/utils/api/apiCall';
import { buildUrl } from 'libs/utils/api';
import { SharedErrors } from 'libs/utils/api/types';

export interface InformationDetails {
  title: string;
  mainContent: string;
  slug: string;
}

export interface InformationDetailsResponse {
  information: InformationDetails;
}

export const getInformationDetails = (slug: string) => {
  const url = buildUrl(`/information/${slug}`);
  return apiCall.get<InformationDetailsResponse, SharedErrors>({ url });
};
