/* eslint-disable react/destructuring-assignment */
import * as React from 'react';

import Footer from 'styleguide/components/Footer/Footer';
import Grid from 'styleguide/components/Grid/Grid';
import TitleContainer from 'bundles/App/pages/Account/TitleContainer/TitleContainer';
import Meta, { RobotsType } from 'styleguide/components/Meta/Meta';

import { Status } from 'libs/utils/api/types';
import HtmlContent from 'styleguide/components/HtmlContent/HtmlContent';
import AppContext from 'contexts/AppContext/AppContext';
import { InformationDetails } from 'contexts/AppContext/types';
import { getInformationDetails } from 'api/information';
import { useLocation } from 'react-router-dom';

interface Props {
  defaultTitle: string;
  slug: string;
  metaTitle: string;
  description: string;
  canonical: string;
  keywords?: string | '';
  robots?: RobotsType[];
}

const InformationPage = (props: Props) => {
  const appContext = React.useContext(AppContext);
  const [information, setInformation] = React.useState<InformationDetails>(appContext.options?.information);
  const location = useLocation();

  React.useEffect(() => {
    if (!information || information.slug !== props.slug) {
      getInformationDetails(props.slug).then(res => {
        if (res.status === Status.Ok) {
          setInformation(res.payload.information);
        }
      });
    }
  }, [location.pathname]);

  return (
    <>
      <Meta
        title={props.metaTitle}
        description={props.description}
        keywords={props.keywords}
        canonical={props.canonical}
        robots={props.robots}
      />
      <TitleContainer title={information?.title} />
      <Grid.Container>
        <Grid>
          <Grid.Row className="justify-start">
            <Grid.Col sm={12} md={1} />
            <Grid.Col sm={12} md={10}>
              <HtmlContent className="m-2" content={information?.mainContent} />
            </Grid.Col>
            <Grid.Col sm={12} md={1} />
          </Grid.Row>
        </Grid>
      </Grid.Container>
      <Footer />
    </>
  );
};
export default InformationPage;
